import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import './workshop.scss'

const WorkshopsPage = () => (
  <Layout>
    <SEO title="Workshops" />
    <center>
      <h1>Workshops</h1>
    </center>

    <section className="workshop">
      <h2>TypeScript: from novice to expert</h2>
      <p>
        The JavaScript Eco System is moving to TypeScript. Fast. This workshop
        will explain why and when you might want to use TypeScript, and get you
        up and running with all the essentials. In this workshop we will cover
        practically the full language. And we will go deep.
      </p>
      <img
        alt="Workshop @ react finland, 2018"
        src={require('../images/workshop1.jpg')}
      />
      <ul>
        <li>
          Introduction to type systems in general and TypeScript specifically.
          Why TypeScript? And when (not)? What is the underlying model?{' '}
        </li>
        <li>Project setup and migration strategies</li>
        <li>The basics: Primitives, interfaces, classes, generics. </li>
        <li>Control flow and type inference</li>
        <li>
          <code>any</code>, <code>never</code>, and <code>unknown</code>. Your
          buddies for life!{' '}
        </li>
        <li>
          <code>type</code>, type operators and modifiers: <code>private</code>,{' '}
          <code>readonly</code>, <code>&</code>, <code>|</code>,{' '}
          <code>infer</code>, <code>this</code>, <code>typeof</code>,{' '}
          <code>is</code>, <code>?</code> and <code>!</code>
        </li>
        <li>
          Typing complex functions and objects: Tuples, index signatures, mapped
          types, conditional types, variadic arguments, overloading, declaration
          merging
        </li>
        <li>Namespaces and typing external packages</li>
        <li>Primer to TypeScript and React (Optional)</li>
      </ul>
      <p>
        Subjects will be discussed by with real-life examples and practiced in
        exercises. For example you will have to design types for existing code,
        or fix complicated type errors.
      </p>
      <p>
        This workshop is also suitable for those that have basic experience with
        TypeScript. Since the basics only take only ~2 hours to cover, most of
        the day is still super valuable to enhance your TypeScript skills, even
        if you already have real project experience with TypeScript! Did you
        ever encounter super confusing types in some of the libraries you use?
        Did you get some super vague compiler errors? After this workshop you
        will be able to understand them!
      </p>
      <p>
        About the author: Michel has been working with TypeScript full time for
        the last 3 years. He authored several open source libraries (such as
        MobX and MobX-state-tree) that are written in TypeScript and leverage
        some of it's finest features.
      </p>
      <p>
        Pre-requisites: Familiarity with JavaScript and modern syntax features
        (ES2015).
      </p>
      <p>
        Feel free to request a <a href="mailto:info@michel.codes">quote</a> to
        deliver this workshop on your company or conference.
      </p>
      <img
        className="testimonial"
        alt="Thank You email by attendee"
        src={require('../images/testimonial.png')}
      />
    </section>

    <section className="workshop">
      <h2>TypeScript for React devs</h2>
      <p>
        The JavaScript Eco System is moving to TypeScript. Fast. TypeScript has
        some unique, very powerful features that fit very well with the dynamic
        nature of the JavaScript language. This workshop will get you up and
        running with all the basics and the most important advanced patterns of
        TypeScript.
      </p>
      <p>
        But beyond that, this is a workshop for React devs! So we will discuss
        extensively how to combine TypeScript with a React code base. To
        practice the theory, during the hands-on parts of this workshop we will
        be migrating an application from plain JavaScript to TypeScript.
      </p>
      <p>
        About the author: Michel has been working with TypeScript full time for
        the last 3 years. He authored several open source libraries (such as
        MobX and MobX-state-tree) that are written in TypeScript and leverage
        some of it's finest features.
      </p>
      <p>
        Pre-requisites: Familiarity with React, JavaScript and modern syntax
        features (ES2015).
      </p>
      <p>
        Feel free to request a <a href="mailto:info@michel.codes">quote</a> to
        deliver this workshop on your company or conference.
      </p>
    </section>
    <section className="workshop">
      <h2>Advanced State Management with MobX and MobX-state-tree</h2>
      <hr />
      <p>
        MobX is a state management library which is very popular in the React
        scene (notable users include Microsoft, Amazon, Lyft, SAP and DICE), and
        applicable outside of React as well. Its core principle is simply:
        <em>
          &ldquo;Anything that can be derived from the application state, should
          be derived. Automatically.&rdquo;
        </em>{' '}
        MobX takes reactive programming to the next level and turns your state
        into a reactive model. The result is very straightforward and concise
        code.
      </p>
      <img
        alt="Workshop @ react amsterdam, 2017"
        src={require('../images/workshop2.jpg')}
      />
      <p>
        In this workshop you will learn all you need to know about MobX and how
        to leverage MobX with React. We will start with the basics. What is the
        philosophy behind MobX? How do you reason with MobX. What is transparent
        reactivity? You will learn how MobX boosts productivity, but also how to
        avoid common pitfalls. You will see how you can use MobX to crank out
        PoCs. Next, we will discuss how MobX is used at Mendix to manage a
        problem domain that is described in more than 1500 classes.
      </p>
      <p>
        In the afternoon we will dive into more advanced subjects, like modeling
        asynchronous processes and state hydration. We will take a look at the
        basics of Mobx-State-Tree, which helps organize complex problem domains
        by providing clear architectural patterns, an API to declare your state
        shape and all the tools needed to do advanced stuff like time
        travelling, undo / redo, patch generation, middle-ware etc.
      </p>
      <p>
        Even if you don't plan on using MobX yet, leverage this to familiarize
        yourself with different ways to organize state.
      </p>
      <p>
        To attend this workshop you need to be familiar with JavaScript
        (ES2015). Knowing the basics of React is recommended.
      </p>
      <p>
        Variations of this workshop are also possible:
        <ul>
          <li>4 hour introduction workshop to MobX only</li>
          <li>
            2 hour introduction workshop to MobX-State-Tree (basic MobX
            knowledge is recommended)
          </li>
        </ul>
      </p>
      <p>
        Feel free to request a <a href="mailto:info@michel.codes">quote</a> to
        deliver this workshop at your company or conference.
      </p>
    </section>
  </Layout>
)

export default WorkshopsPage
